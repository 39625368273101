// assets/react/controllers/Carousel.jsx
import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import noVisual from "../../images/novisual.png";

// Set Slider arrows
function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, 
        display: "block",
        background: "white",
        opacity: 1,
        width: 60,
        height: 60,
        zIndex: 4
      }}
      onClick={onClick}
    />
  );
}

export default class Responsive extends Component {
  render() {

    const { entities } = this.props;

    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 4000,
      prevArrow: <Arrow />,
      nextArrow: <Arrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    };

    return (
      <div>
        <Slider {...settings}>
          {entities.map((entity, index) => (
            <div key={index}>
              <div className="fr-tile tile-height tile-bottom-color">
                <div className="fr-tile__body tile-title-padding fr-enlarge-link mx-2">
                  <div className="fr-tile__title">
                    <div className="fr-mx-2w fr-py-2w fr-py-75">
                      <a className="fr-tile__link" href={entity.urlCollection || ''} aria-label={entity.acronym || ''} target="_blank">
                        {entity.image ? (
                          <img src={entity.image} className="fr-responsive-img carousel-image" alt={entity.acronym || ''} />
                        ) : (
                          <img src={noVisual} className="fr-responsive-img carousel-image" alt={entity.acronym || ''} />
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
