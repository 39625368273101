import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import ReactDOMServer from 'react-dom/server';
import stripes from './stripes.svg'
import { getGeoData, combineMultiPolygons, labelliseIcon, trajectoryIcon, patternOptions } from '../../js/maps.js';
import 'leaflet.pattern';
import Lottie from 'lottie-react';
import loadingMap from './loading_map.json';
import labellise from './labellise.png';
import trajectory from './trajectoire.png'

const language = (window.location.href.indexOf('/en/') !== -1) ? 'en' : 'fr';

const currentURL = window.location.href;
const urlParts = currentURL.split('/');
const previousURL = urlParts.slice(0, -2).join('/');

const fetchWorkshopsFromDatabase = async () => {
  try {
      const response = await fetch(previousURL+'/dataworkshops');
      if (!response.ok) {
          throw new Error('Erreur lors de la requête');
      }
      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Erreur lors de la récupération des emplacements depuis la base de données:', error);
  }
};

function InteractiveMap() {
  const [dataworkshops, setDataworkshops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMapData, setLoadingMapData] = useState(false)


  // Function to to get gps coords from string address and place marker
  const geocodeAndPlaceMarker = async (workshop, popupContent, map) => {

    var stripes = new L.StripePattern(patternOptions);
    stripes.addTo(map);

    try {
      setLoadingMapData(true);
      
      var data = {}
      let locations = {
        type: "MultiPolygon",
        coordinates: []
      };

      if (workshop.mainLocationData) {
        data = JSON.parse(workshop.mainLocationData);

        for (const location of JSON.parse(workshop.mainLocationData).locations){
          locations = combineMultiPolygons(locations, location)
        }

        L.marker([data.mainLocation.lat, data.mainLocation.lon],{icon : workshop.workshopType === 'Labellisé' ? labelliseIcon : trajectoryIcon}).addTo(map).bindPopup(popupContent)

      } else {
        data = await getGeoData(workshop.locationValue);
        
        for (const location of workshop.locations) {
          const locationData = await getGeoData(location);
          locations = combineMultiPolygons(locations, locationData.geojson);
        }

        L.marker([data.lat, data.lon],{icon : workshop.workshopType === 'Labellisé' ? labelliseIcon : trajectoryIcon}).addTo(map).bindPopup(popupContent)

      }

      let polygon = L.geoJSON(locations, {
          color: '#16927b', 
          fillColor: '#1abc9c', 
          fillOpacity: 0.8,
          fillPattern: workshop.workshopType === 'Sur la trajectoire' && stripes,
          crs: L.CRS.EPSG4326
        })
        
      polygon.addTo(map).bindPopup(popupContent);
      setLoadingMapData(false);

    } catch (error) {
      console.error('Erreur lors du géocodage:', error);
    }

  };

  // Loading dataworkshops
  useEffect(() => {
    setLoading(true)
    fetchWorkshopsFromDatabase()
    .then(data =>{
      setDataworkshops(data)
      setLoading(false);
    })
  }, [])
  
  // Displaying dataworkshops
  useEffect(() => {
    if ( dataworkshops.length > 0 ) {

      // Map init :
      const map = L.map('map').setView([46.603354, 1.888334], 5, { crs: L.CRS.EPSG4326 }); 

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
      dataworkshops.forEach(workshop => {
        const popupContent = document.createElement('div');
        popupContent.classList.add('map_popup');

        const popupHeader = document.createElement('div');
        popupHeader.classList.add('popup_header');

        const icon = document.createElement('img');
        icon.src = workshop.workshopType === 'Labellisé' ? labellise : trajectory;
        icon.classList.add('header_icon');
        icon.title = workshop.extendedName;
        icon.alt = workshop.extendedName;

        const headerContent = document.createElement('div');
        headerContent.classList.add('header_content');

        const title = document.createElement('h2');
        title.classList.add('title');
        title.textContent = workshop.extendedName;

        const acronym = document.createElement('div');
        acronym.classList.add('acronym');
        acronym.textContent = workshop.acronym;

        headerContent.appendChild(title);
        headerContent.appendChild(acronym);

        popupHeader.appendChild(icon);
        popupHeader.appendChild(headerContent);

        popupContent.appendChild(popupHeader);

        if (workshop.image) {
          const image = document.createElement('img');
          image.classList.add('image');
          image.src = workshop.image;
          image.title = workshop.extendedName;
          image.alt = workshop.extendedName;
          popupContent.appendChild(image);
        }

        const url = document.createElement('div');
        url.classList.add('url');

        const link = document.createElement('a');
        link.classList.add('fr-tag', 'fr-fi-arrow-right-line', 'fr-tag--icon-right', 'dataworkshop-link');
        link.id = workshop.acronym;
        link.textContent = language === 'fr' ? "En savoir plus" : "More informations";
        link.style.cursor = 'pointer';

        url.appendChild(link);

        popupContent.appendChild(url);

        const handleClick = () => {
          const escapedAcronym = workshop.acronym.replace(/'/g, "\\'");
          const element = document.querySelector(`button[aria-controls='accordion-${escapedAcronym}']`);
          if (element) {
            const accordions = document.querySelectorAll('[aria-controls^="accordion-"]');
            accordions.forEach(accordion => {
              accordion.setAttribute('aria-expanded', 'false');
            });
            element.setAttribute('aria-expanded', 'true');
            element.scrollIntoView({ behavior: 'smooth' });
          }
        };

        link.addEventListener('click', handleClick);

        geocodeAndPlaceMarker(workshop, popupContent, map);
      });

      const legend = L.control({ position: 'bottomright' });
      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'legend');
        div.innerHTML += language === 'fr' ? '<span>Légende</span>' : '<span>Legend</span>';
        div.innerHTML += `<div class="ligne"><i class="square" style="background:#1abc9c"></i>
        ${language === 'fr' ? 'Ateliers labellisés' : 'Labelled data management clusters'}</div>`;
        div.innerHTML += '<div class="ligne">' + `<img src=${stripes} class="square" alt="legend labelled data management clusters">
        ${language === 'fr' ? 'Ateliers sur la trajectoire' : 'On the pathway to labelling'}</div>`;
        
        return div;
      };
      legend.addTo(map);
        
      const legendCss = document.createElement('style');
      legendCss.innerHTML = `
          .legend {
              background-color: white;
              padding: 10px;
              border-radius: 5px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          .legend .ligne{
            display: flex;
            align-items: center;
            margin: 10px;
          }
          .legend span {
              margin-top: 0;
              margin-bottom: 5px;
              font-size: 14px;
          }
          .legend .square {
              width: 20px;
              height: 20px;
              display: inline-block;
              margin-right: 5px;
          }
      `;
      document.querySelector('head').appendChild(legendCss);
    }
    
  }, [dataworkshops]);

  const loadingStyle = {
    height: 100,
  };

  const legalText = language === 'fr' ? "Carte non contractuelle - Vous ne savez pas à quel atelier de la donnée vous adresser ou souhaitez mieux connaitre les zones géographiques couvertes par chacun ? Contacter directement les coordinateurs d'ateliers dans les volets dépliants ci-dessous." : 
  "Non-contractual map - You don't know which data management cluster to contact or want to know more about the geographical areas covered by each one? Contact the workshop coordinators directly in the drop-down sections below."

  return (
    <div>
      <h2 className="fr-h2 fr-mb-1v mb-4">
      {language === 'fr' ? `${dataworkshops.length} ateliers constituent aujourd'hui le réseau des ateliers de la donnée :` 
      : `${dataworkshops.length} data management clusters are today part of the national network:`}
      </h2>
      {loadingMapData && <Lottie animationData={loadingMap} style={loadingStyle} loop={true} /> }
      {loading ? (
        <Lottie animationData={loadingMap} style={loadingStyle} loop={true} />
      ) :
      (
        <div id="map" style={{ width: '100%', height: '500px' }}></div>
      )}
      <p className="fr-text--sm fr-mb-1v mb-4">{legalText}</p>
    </div>
  );
}
  
export default InteractiveMap;
